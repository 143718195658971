


export function BreakfastFood () {
    return (
<div>
    <div id="recent-work-container" className="row justify-content-center">
        <div className="col-10 text-center pt-4">
            <h6>BREAKFAST FOOD</h6>
        </div>
    </div>
    <div className="row justify-content-center">
        <img width="100%" className="col-11" src="../../../images/projects/breakfast-food/tim.jpg" loading="lazy" />
    </div>
    <div className="row justify-content-center">
        <img src="../../../images/projects/breakfast-food/cup-and-chair.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/breakfast-food/empty-seat.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/breakfast-food/ketchup-table.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/breakfast-food/corner-seat.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/breakfast-food/sauce-table.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/breakfast-food/wales-cafe.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/breakfast-food/coffees.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/breakfast-food/post-breakfast.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/breakfast-food/newcastle-carpet.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/breakfast-food/ketchup-grab.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/breakfast-food/salt-and-ketchup.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/breakfast-food/ritz-carpet.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
        <img src="../../../images/projects/breakfast-food/flat-white.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
    </div> 
</div>
    )}